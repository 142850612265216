
<template>
  <div>
    <v-card outlined style='border-radius: 18px'>
      <v-card-title class='primary white--text py-3 px-5'>
        Exportações
      </v-card-title>
      <v-text-field 
        v-model="search" 
        outlined 
        flat 
        clearable 
        solo 
        hide-details 
        prepend-inner-icon="mdi-magnify" 
        label="Buscar (status ou exportação)" 
        :class="['my-2 ml-3', $vuetify.breakpoint.xs ? 'mr-3' : 'mr-2']" 
        dense 
      ></v-text-field>
      <card-loading-transparent v-if="loading.data_exports"/>
      <v-card-text class="px-0" v-if="!loading.data_exports">

        <v-data-table 
          class="elevation-0" 
          :headers="headers" 
          :search="search" 
          :items="data_exports" 
          :options="{itemsPerPage: -1}" 
          :footer-props="{itemsPerPageOptions: [10, 30, 50, 100]}"
          :options.sync="pagination"
          :server-items-length="total_ammount"
          mobile-breakpoint="1"
        >
          <template  v-slot:item.created_at="{item}" >
            {{$dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}}
          </template>
          <template  v-slot:item.concluded_at="{item}">
            {{item.concluded_at ? $dayjs(item.concluded_at).format('DD/MM/YYYY HH:mm') : ''}}
          </template>
          <template  v-slot:item.status="{item}" >
            <v-chip dark :color="statuses_map[item.status].color" small>
              <v-avatar left>
                <v-icon x-small>{{ statuses_map[item.status].icon }}</v-icon>
              </v-avatar>
              {{ statuses_map[item.status]?.text }}
            </v-chip>
          </template>
          <template  v-slot:item.exported_file_url="{item}" >
            <div v-if="item.exported_file_url">
              <v-btn :href="item.exported_file_url" color="success" target="_blank" download small>
                <v-icon left>mdi-download </v-icon>
                Baixar
              </v-btn>
            </div>
            <div v-else>
              {{ $dayjs().diff($dayjs(item.created_at), 'days') >= 3 ? 'Link expirado' : 'URL não gerada' }} 
            </div>
          </template>
        </v-data-table>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Api from '@/api/index';
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'DataExportIndex',
  components: {

  },
  data() {
    return {
      data_exports: [],
      loading: { 
        data_exports: false
      },
      headers: [
        { text: 'Criado em', align: 'center', sortable: true, value: 'created_at' },
        { text: 'Concluido em', align: 'center', sortable: true, value: 'concluded_at' },
        { text: 'Solicitante', align: 'center', sortable: true, value: 'user_name' },
        { text: 'Status', align: 'center', sortable: true, value: 'status' },
        { text: 'Exportação', align: 'center', sortable: true, value: 'file_name' },
        { text: 'Link', align: 'center', sortable: false, value: 'exported_file_url' },
      ],
      statuses_map: {
        'requested': { text: 'Requerido', color: 'amber', icon: 'mdi-check' }, 
        'in_progress': { text: 'Em andamento', color: 'primary', icon: 'mdi-percent' }, 
        'done': { text: 'Pronto', color: 'success', icon: 'mdi-check' }, 
        'error': { text: 'Erro', color: 'error', icon: 'mdi-alert-circle' }, 
        'canceled': { text: 'Cancelado', color: 'blue-grey', icon: 'mdi-times' }
      },
      pagination: {
        page:1,
        itemsPerPage: 10,
        sortBy:['created_at'],
        sortDesc:[true],
        groupBy:[],
        groupDesc:[],
        mustSort:false,
        multiSort:false
      },
      search: '',
      find_param: null,
      searchTimeout: null,
      loaded_filter: false,
      total_ammount: 0
    }
  },
  methods: {
    handleSearch() {
      if(this.search){
        this.loading.data_exports = true
        this.pagination.page = 1
        
        const search = this.search.toLowerCase();

        let find_param = Object.keys(this.statuses_map).find(key => {
          const model = this.statuses_map[key];
          return model.text?.toLowerCase().includes(search);
        });

        this.find_param = find_param
      }else{
        this.find_param = null
      }
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getData();
      }, 1000);
    },
    getData(){
      this.getDataExports()
    },
    getDataExports(){
      this.loading.data_exports = true

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination
      let params = {
        page,
        limit: itemsPerPage,
        sort_field: sortBy[0]? sortBy[0] : null,
        sort_dir: sortBy[0]? (sortDesc[0]? 'desc' : 'asc') : null,
      }
      if(!!this.search){
        params['search'] = this.find_param && this.search ? this.find_param : this.search
      }

      Api.DataExport.index(params).then(req => req.data).then(data => {
        this.data_exports = [...data.data]
        this.total_ammount = data.total
      }).catch( err => {
        console.log(err)
      }).finally(()=>{
        this.loading.data_exports = false
      })
    },
    download(id, format){
      Api.DataExport.show(id).then( r=> {
        console.log(r.headers['content-type'])
        const file = new Blob([r.data], { type: r.headers['content-type'] });
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `batata.${format}`);
        link.click();

      }).catch(err=>{
        console.log(err)
      }).finally(()=>{
      })
    },
    export(data, filename, format) {
      const url = window.URL.createObjectURL(new Blob([data], { type: `application/${format}` }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href)
    },
  },
  mounted(){
    this.getData()
  },
  watch: {
    pagination(nv, ov){
      if(nv.page && nv.page != ov.page) {
        this.getData()
      }
    },
    search(nv, ov) {
      this.handleSearch()
    }
  },
}
</script>